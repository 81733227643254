import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';

import {AppComponent} from '../app.component';
import {WelcomeComponent} from '../Components/Views/Welcome/welcome.component';
import {AboutComponent}  from '../Components/Views/About/about.component';
import {DevelopmentComponent} from '../Components/Views/Development/development.component';
import {BlogComponent}  from '../Components/Views/Blog/blog.component';

import {appMainService} from '../Services/app.mainService';


@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    AboutComponent,
    DevelopmentComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [appMainService],
  bootstrap: [AppComponent]
})
export class AppModule { }
