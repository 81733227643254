import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {appMainService} from '../../../Services/app.mainService';

declare var $: any;

@Component({
  selector: 'About',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title = 'About!';
  AboutMeParagraph = 'Hi, I am a 25 year old computer programmer. I went to Robert Morris University, and studied Information Systems, I graduated in May 2016. \
  Currently I have been working at an investment company utilizing my degree. \
  In the future I hope to develop some of my own Web libraries, apps, and other miscellaneous code projects, which i will feature on the Development Page.' 
  AboutTheWebsite = 'I developed this website from scratch. Using Angular, Bootstrap, and a few other libraries! It is hosted on Googles cloud services!';

  @Output() notifyCurrentPageName = new EventEmitter<string>();

  constructor(private appMainService: appMainService){
    appMainService.currentChildViewName(this.title);
  }

  ngOnInit(){
    $('[data-toggle="popover"]').popover({html:true});
  }


}
