import { Injectable, EventEmitter } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class appMainService {
  currentPage = '';
 
   // Observable current page name source
  notifyCurrentPageName = new BehaviorSubject<string>("");
  // Observable current page name stream
  notifyCurrentPageName$ = this.notifyCurrentPageName.asObservable();
   

  constructor(){
   
  }

   
  //To Change the View Name...
  currentChildViewName(name:string) {
    this.notifyCurrentPageName.next(name);
  }



}