import { Component } from '@angular/core';

@Component({
  selector: 'Welcome',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent {
  title = 'Welcome!';
  welcomemessage = 'This is my personal website, here you can find information about me and the projects I am working on!';
}
