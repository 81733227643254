import { Component, OnInit, OnDestroy } from '@angular/core';
import {appMainService} from './Services/app.mainService';
import {Subscription} from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  currentViewNameSubscription:Subscription;
  title = "Matt Slivka's WebSite";
  currentPage = 'Home';
  date = new Date().getFullYear();
  events: string[] = [];
  opened: boolean;

  constructor(private appMainService: appMainService){
     this.currentViewNameSubscription = appMainService.notifyCurrentPageName$.subscribe(name => this.currentPage = name);
  }

  ngOnInit(){
    this.currentPage = "Home";
  }

  ngOnDestroy() {
    // prevent memory leak when component is destroyed
    this.currentViewNameSubscription.unsubscribe();
  }
}
