import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import {appMainService} from '../../../Services/app.mainService';

declare var $: any;

@Component({
  selector:'Blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  title = 'Blog!';

  constructor(private appMainService: appMainService){
    appMainService.currentChildViewName(this.title);
  }

  ngOnInit(){
    $('[data-toggle="popover"]').popover({html:true});
  }

}
