import { Component } from '@angular/core';
import {appMainService} from '../../../Services/app.mainService';

declare var $: any;

@Component({
  selector: 'Development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.css']
})
export class DevelopmentComponent {
  title = 'Development!';

  constructor(private appMainService: appMainService){
    appMainService.currentChildViewName(this.title);
  }

  ngOnInit(){
    $('[data-toggle="popover"]').popover({html:true});
  }

}
